<template>
  <div class="boxes">
    <h1 class="section-header">Enheter</h1>

    <app-modal @close="showModal = false" v-if="showModal">
      <AddBox @boxAdded="onBoxAdded" :isCompanyAdmin="isCompanyAdmin" :companies="companies"></AddBox>
    </app-modal>

    <div class="row justify-content-between mt-4">
      <div class="row col-md-8">
        <input-field v-model="search" class="col-md-3" @input="searchBoxes" placeholder="Sök ..."></input-field>
        <v-select v-if="!isCompanyAdmin" class="col-md-4 mt-2" @input="searchBoxes" v-model="company"
                  :reduce="item => item._id" :options="companies" label="name" placeholder="Företag"></v-select>
      </div>
      <app-button class="btn--secondary mr-3"  @click="showModal = true">Lägg till enhet</app-button>
    </div>

    <div class="box box--outline mt-3">
      <app-table :head="head">
        <template v-for="(box, index) in boxes">
          <tr :class="{'expanded': box.expanded}" :key="box._id" @click="box.expanded = !box.expanded">
            <td>{{ box.boxId }}</td>
            <td>{{ box.vehicle.licencePlate ? box.vehicle.licencePlate : 'Inget fordon' }}</td>
            <td>{{ box.company.name }}</td>
            <td>
              <div class="d-flex justify-content-end align-items-center">
                <div class="box-icon" v-if="box.vehicle.licencePlate">
                  <app-icon class="active-icon" name="checkmark"></app-icon>
                </div>
                <app-icon @click.native.stop="box.expanded = !box.expanded" class="arrow ml-3"
                          :class="{'rotate-arrow': !box.expanded}" name="chevron-down"/>
              </div>
            </td>
          </tr>
          <tr v-if="box.expanded">
            <td colspan="3">
              <div class="row justify-content-between align-items-center pb-4">
                <div class="col-md-3 select-item">
                  <label class="mb-2">Fordon</label>
                  <v-select :disabled="box.bound" :selectable="option => option.company._id === box.company._id"
                            :options="vehicles" v-model="box.vehicle.licencePlate" :reduce="value => value.licencePlate"
                            label="licencePlate">
                    <template #no-options>Inga tillgängliga val.</template>
                  </v-select>
                </div>
                <div class="d-flex">
                  <app-button class="btn--red mr-2" @click="deleteBox(box.boxId)">Radera</app-button>
                  <app-button class="btn--secondary mr-2" @click="bindVehicleToBox(box.boxId, box.bound)">
                    <template v-if="!box.bound">Anslut</template>
                    <template v-else>Koppla ifrån</template>
                  </app-button>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </app-table>
    </div>

    <div class="d-flex justify-content-end mt-5">
      <app-pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @pageChanged="onPageChanged"
      >
      </app-pagination>
    </div>

  </div>
</template>

<script>
import boxesHttp from '@/http/boxes'
import companiesHttp from "@/http/companies";
import AddBox from "@/components/boxes/AddBox";
import vehiclesHttp from '@/http/vehicles'
import {mapGetters} from "vuex";
import AppPagination from "@/components/shared/AppPagination";
import {getTotalPages} from "@/utils/pagination";
import {cancelButton, deleteDialogMessage, deleteSuccess, yesButton} from "@/utils/translations";
import {warningTranslations} from "@/utils/error.helper";

export default {
  components: {AppPagination, AddBox},
  data() {
    return {
      showModal: false,
      boxes: [],
      companies: [],
      vehicles: [],
      currentPage: 1,
      totalPages: 1,
      search: null,
      company: null,
      head: [
        {name: 'IMEI-nummer'},
        {name: 'Ansluten till fordon'},
        {name: 'Företag'},
        {name: '', width: 15}
      ]
    }
  },
  computed: {
    ...mapGetters([
      'isCompanyAdmin'
    ])
  },
  methods: {
    async getCompanies() {
      try {
        const {companies} = await companiesHttp.getCompanies();
        this.companies = companies;
      } catch (e) {
        this.$notify.error('Error fetching companies')
      }
    },
    async getBoxes() {
      try {
        const {boxes, totalCount} = await boxesHttp.getBoxes(null, this.currentPage, 10, this.search, this.company);
        this.boxes = boxes.map(item => ({
          ...item,
          expanded: false,
          bound: !!item.vehicle.licencePlate
        }))

        this.totalPages = getTotalPages(totalCount);
      } catch (e) {
        console.log(e)
        this.$notify.error('Error fetching boxes')
      }
    },
    async deleteBox(id) {

      try {
        await this.$confirm('', deleteDialogMessage, {
          confirmButtonText: yesButton,
          cancelButtonText: cancelButton,
          type: 'warning'
        })
      } catch (e) {
        return;
      }

      try {
        await boxesHttp.deleteBox(id);

        this.$notify.success('Enheten togs bort!')
        await this.getBoxes();
      } catch (e) {
        this.$notify.success(deleteSuccess)
      }
    },
    onPageChanged(page) {
      this.currentPage = page;
      this.getBoxes();
    },
    async bindVehicleToBox(boxId, bound) {
      try {
        const box = this.boxes.find(box => box.boxId === boxId);
        const {vehicle, company} = box;

        if (!vehicle.licencePlate) {
          this.$notify.error('Välj ett fordon i listan!');
          return;
        }

        // const selectedVehicle = this.vehicles.find(item => item.licencePlate === vehicle.licencePlate);

        const action = bound ? 'unbind' : 'bind';

        await boxesHttp.setBoxToVehicleLink(vehicle.licencePlate, boxId, company._id, action);

        await this.getBoxes();
        await this.getVehicles();
      } catch (e) {
        this.$notify.error(warningTranslations.SOMETHING_WENT_WRONG);
      }
    },
    async getVehicles() {
      try {
        const {vehicles} = await vehiclesHttp.getVehicles({boxBindStatus: 'unbound'});
        this.vehicles = vehicles;
      } catch (e) {
      }
    },
    searchBoxes() {
      this.currentPage = 1;
      this.getBoxes();
    },
    onBoxAdded() {
      this.getBoxes();
      this.showModal = false;
    },
    getCompanyName(id) {
      const company = this.companies.find(item => item._id === id);
      return company ? company.name : '-'
    }
  },
  async created() {
    if (!this.isCompanyAdmin) {
      await this.getCompanies();
    }

    await this.getBoxes();
    await this.getVehicles();
  }
}
</script>

<style lang="scss">
.box-icon {
  padding: 5px;
  background: var(--color-gray-light);
  width: 32px;
  height: 32px;
  border-radius: 8px;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);

  .active-icon {
    path {
      stroke: var(--color-secondary);
    }
  }
}
</style>
