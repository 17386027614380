import axios from "axios";

export default {
  getCompanies(page = null, limit, search = null) {
    const params = {
      limit,
      skip: page ? page - 1 : null,
      search
    }

    return axios.get('/companies', {params})
  },
  addCompany(data) {
    return axios.post('/companies', {...data})
  },
  updateCompany(data) {
    return axios.put('/companies', {...data})
  },
  deleteCompany(id) {
    return axios.delete(`/companies/${id}`)
  }
}
