import axios from 'axios';

export default ({
  getVehicles({bindStatus = null, limit = null, page = null, search = null, company = null, boxBindStatus = null, includeDeleted = null} = {}) {
    page = page ? page - 1 : null;
    return axios.get('/vehicles', {params: {bindStatus, limit, skip: page, search, company, boxBindStatus, includeDeleted}})
  },
  addVehicle(vehicleData) {
    return axios.post('/vehicles', {...vehicleData})
  },
  updateVehicle(vehicleData) {
    return axios.put('/vehicles', vehicleData);
  },
  deleteVehicle(uid) {
    return axios.delete(`/vehicles/${uid}`);
  },

  getMakers() {
    return axios.get('/vehiclecodebook/make')
  },
  getModelsForMake(make) {
    return axios.get(`/vehiclecodebook/make/${make}/model`)
  },

  getCurrentLocation(licencePlate) {
    return axios.get(`vehicles/${licencePlate}/current-location`)
  }
})
