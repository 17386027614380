<template>
  <div>
    <h1 class="section-header">Enhetsinformation</h1>

    <div class="row mt-5">
      <div class="col-md-6">
        <input-field v-model="form.boxId" label="IMEI-nummer"></input-field>
      </div>
      <div class="col-md-6 select-item" v-if="!isCompanyAdmin">
        <label class="mb-2">Välj företag</label>
        <v-select :options="companies" label="name" v-model="form.company" :reduce="item => item._id"></v-select>
      </div>
    </div>

    <div class="mt-5 d-flex justify-content-end">
      <app-button :loading="loading" class="btn--secondary" @click="addBox">Lägg till</app-button>
    </div>

  </div>
</template>

<script>
import boxesHttp from '@/http/boxes'
import {createSuccess, } from "@/utils/translations";
import {makeErrorMessage} from '@/utils/error.helper'

export default {
  props: ['companies', 'isCompanyAdmin'],
  data() {
    return {
      form: {
        boxId: '',
        company: null
      },
      loading: false,
    }
  },
  methods: {
    async addBox() {
      if (!this.form.boxId) {
        this.$notify.error('IMEI-nummer krävs!');
        return;
      }

      try {
        this.loading = true;

        await boxesHttp.addBox(this.form);
        this.$emit('boxAdded')
        this.$notify.success(createSuccess('Enheten'))
      } catch (e) {
        const message = makeErrorMessage(e)
        this.$notify.error(message)
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
