export const warningTranslations = Object.freeze({
  UNKNOWN_ERROR: 'Hoppsan! Något gick fel, försök igen!',
  INVALID_CREDENTIALS: 'Fel inloggningsuppgifter!',
  BOX_ALREADY_EXISTS: 'Detta IMEI-nummer finns redan i bruk!',
  VEHICLE_ALREADY_EXISTS: 'Detta fordon finns redan!',
  USER_ALREADY_EXISTS: 'Denna e-post används redan som användarnamn!',
  COMPANY_ALREADY_EXISTS: 'Detta företag finns redan!',
  PASSWORD_RESET_SUCCESS: 'En återställningslänk har skickats till din e-post!',
  BOX_DELETED: 'Enheten togs bort!',
  SOMETHING_WENT_WRONG: 'Hoppsan! Något gick fel, försök igen!'
})

export const errorCodes = Object.freeze({
  ERR_0000: warningTranslations.UNKNOWN_ERROR,
  ERR_0001: warningTranslations.INVALID_CREDENTIALS,
  ERR_0002: warningTranslations.BOX_ALREADY_EXISTS,
  ERR_0003: warningTranslations.VEHICLE_ALREADY_EXISTS,
  ERR_0004: warningTranslations.USER_ALREADY_EXISTS,
  ERR_0005: warningTranslations.COMPANY_ALREADY_EXISTS
})


export const getError = (errorCode) => {
  if(errorCodes[errorCode]) {
    return errorCodes[errorCode]
  }
  return warningTranslations.UNKNOWN_ERROR
}

export const makeErrorMessage = (error) => {
  const {drivelogErrorCode} = error?.response.data
  return getError(drivelogErrorCode)
}
