import axios from "axios";

export default ({
  getBoxes(bindStatus = null, page = null, limit = null, search = null, company = null) {
    const params = {
      limit,
      skip: page ? page - 1 : null,
      search,
      bindStatus,
      company
    }
    return axios.get('/boxes', {params})
  },
  addBox(boxData) {
    return axios.post('/boxes', boxData)
  },
  setBoxToVehicleLink(licencePlate, boxId, company, action = 'bind') {
    return axios.put(`/vehicles/${licencePlate}/${action}/box/${boxId}?company=${company}`)
  },
  deleteBox(id) {
    return axios.delete(`/boxes/${id}`)
  }
})
