<template>
  <div>
    <paginate
        :page-count="totalPages"
        :prev-text="arrow"
        :next-text="arrow"
        prev-class="prev"
        next-class="next"
        :value="currentPage"
        :click-handler="onPageChanged"
        :container-class="'vue-pagination'">
    </paginate>
  </div>
</template>

<script>
export default {
  props: ['totalPages', 'currentPage'],
  computed: {
    arrow() {
      return `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 8.625L12 15.375L18.75 8.625" stroke="#161818" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `
    }
  },
  methods: {
    onPageChanged(page) {
      this.$emit('pageChanged', page);
    }
  }
}
</script>

<style lang="scss">

.vue-pagination {
  display: inline-flex;
  list-style: none;
  background: #f3f5f9;
  padding-left: 0;
  padding: 5px 10px;
  border-radius: 30px;
  margin: 0;

  li {
    width: 32px;
    height: 32px;
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);

    a {
      font-weight: 600;
      color: var(--color-gray-blueish);
      font-size: 14px;
    }
  }

  .active {
    background: var(--color-secondary);
    border-radius: 50%;

    a {
      color: white !important;
    }
  }
  .prev, .next {
    a {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
    }
  }
  .prev {
    padding-right: 12px;
  }
  .next {
    padding-left: 12px;
  }
  .prev svg {
    transform: rotate(90deg);
  }
  .next svg {
    transform: rotate(-90deg);
  }
}

</style>
